import { Controller } from "@hotwired/stimulus"
import LeaderLine from "leader-line-new"
// Connects to data-controller="editor--multi-inapp--lines"
export default class extends Controller {
  connect() {
    this.lines = []
    // Workaround : connect is called when tree is re-rendered -> recalculate here
    document.dispatchEvent(new CustomEvent("updateProjectSize"))

    document.addEventListener("redrawLines", this.redrawLines.bind(this))
  }

  drawLines() {
    let branches = document.querySelectorAll(".tree-branch")
    branches.forEach((branch) => {
      let start = branch
      let inappId = branch.dataset.inappId

      // Don't draw lines to sections with no buttons
      let ends = document.querySelectorAll(`.transitions-inapp-${inappId}:has(.transition-button)`)

      ends.forEach((end) => {
        var line = new LeaderLine(start, end, {
          color: "#C4C4C4",
          size: 2,
          startSocket: "bottom",
          endSocket: "top",
          path: "grid",
          endPlug: "behind",
        })
        this.lines.push(line)
      })
    })

    // Move lines to tree view
    document.querySelectorAll(".leader-line").forEach((line) => {
      document.getElementById("tree").appendChild(line)
    })
  }

  removeLines() {
    document.querySelectorAll(".leader-line").forEach((line) => {
      line.remove()
    })
    this.lines = []
  }

  redrawLines() {
    window.scrollTo(0, 0)
    this.removeLines()
    this.drawLines()
  }
}
